import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import ChurchIndex from '../Index'
import 'react-toastify/dist/ReactToastify.css'
import LoadingSpinner from '../../../components/shared/LoadingSpinner'

const ChurchRegistrationEditForm = () => {
  const { id, registrationId } = useParams()
  const navigate = useNavigate()
  const [packages, setPackages] = useState([])
  const [formData, setFormData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [registrationRes, packagesRes] = await Promise.all([
          axios.get(`/api/v1/church/event/register/${registrationId}`),
          axios.get(`/api/v1/church/event/register/all-packages/${id}`),
        ])

        setFormData(registrationRes.data)
        setPackages(packagesRes.data.packages)
      } catch (error) {
        toast.error('Failed to load registration or packages')
        console.error('Fetch error:', error)
      } finally {
        setLoading(false)
      }
    }

    if (id && registrationId) {
      fetchData()
    }
  }, [id, registrationId])

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }))
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    setErrorMsg('')

    try {
      await axios.put(
        `/api/v1/church/event/register/${registrationId}`,
        formData
      )
      toast.success('Updated successfully')
      navigate(`/event/${id}`)
    } catch (error) {
      const message =
        error.response?.data?.message || 'Failed to update registration'
      setErrorMsg(message)
      toast.error(message)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <ChurchIndex>
      <div className='d-flex justify-content-end gap-4 mb-3'>
        <button
          className='btn btn-success btn-sm'
          style={{ textTransform: 'uppercase' }}
          onClick={() => navigate(`/event/${id}`)}
        >
          Registration List
        </button>
        <button
          className='btn btn-success btn-sm'
          disabled
          style={{
            textTransform: 'uppercase',
            fontWeight: 600,
            cursor: 'not-allowed',
            paddingTop: '6px',
            paddingBottom: '6px',
          }}
        >
          Registration Form
        </button>
        <button
          className='btn btn-success btn-sm'
          style={{ textTransform: 'uppercase' }}
          onClick={() => navigate(`/event/${id}/payment`)}
        >
          Payment
        </button>
        <button
          className='btn btn-success btn-sm'
          style={{ textTransform: 'uppercase' }}
          onClick={() => navigate(`/event/${id}/transportation`)}
        >
          Transportation
        </button>
      </div>

      <div
        className='fw-bold text-center mb-2'
        style={{
          fontSize: '17px',
          color: '#219119',
          textTransform: 'uppercase',
        }}
      >
        Edit Registration
      </div>
      {errorMsg && (
        <div
          className='alert alert-danger text-center'
          style={{ padding: '8px 12px' }}
        >
          {errorMsg}
        </div>
      )}

      {loading ? (
        <LoadingSpinner />
      ) : formData ? (
        <div className='bg-white p-4 rounded border'>
          <div className='row g-3'>
            <div className='col-md-1 col-sm-6'>
              <label className='form-label'>
                Gender <span className='require'>*</span>
              </label>
              <select
                className='form-control'
                value={formData.gender || ''}
                onChange={(e) => handleChange('gender', e.target.value)}
              >
                <option value=''>Choose</option>
                <option value='1'>Brother</option>
                <option value='2'>Sister</option>
              </select>
            </div>
            <div className='col-md-3 col-sm-6'>
              <label className='form-label'>
                Name <span className='require'>*</span>
              </label>
              <input
                type='text'
                className='form-control'
                value={formData.name || ''}
                onChange={(e) => handleChange('name', e.target.value)}
              />
            </div>
            <div className='col-md-3 col-sm-6'>
              <label className='form-label'>Chinese Name</label>
              <input
                type='text'
                className='form-control'
                value={formData.chiName || ''}
                onChange={(e) => handleChange('chiName', e.target.value)}
              />
            </div>
            <div className='col-md-3 col-sm-6'>
              <label className='form-label'>Email</label>
              <input
                type='email'
                className='form-control'
                value={formData.email || ''}
                onChange={(e) => handleChange('email', e.target.value)}
              />
            </div>
            <div className='col-md-2 col-sm-6'>
              <label className='form-label'>
                Phone Number <span className='require'>*</span>
              </label>
              <input
                type='text'
                className='form-control'
                value={formData.phoneNo || ''}
                onChange={(e) => handleChange('phoneNo', e.target.value)}
              />
            </div>
          </div>
          <div className='row g-3 mt-1'>
            <div className='col-md-1 col-sm-6'>
              <label className='form-label'>
                Age <span className='require'>*</span>
              </label>
              <input
                type='number'
                className='form-control'
                value={formData.age || ''}
                onChange={(e) => handleChange('age', e.target.value)}
              />
            </div>
            <div className='col-md-3 col-sm-6'>
              <label className='form-label'>
                I.C/Passport <span className='require'>*</span>
              </label>
              <input
                type='text'
                className='form-control'
                value={formData.icNo || ''}
                onChange={(e) => handleChange('icNo', e.target.value)}
              />
            </div>

            <div className='col-md-3 col-sm-6'>
              <label className='form-label'>
                Language <span className='require'>*</span>
              </label>
              <select
                className='form-control'
                value={formData.language || ''}
                onChange={(e) => handleChange('language', e.target.value)}
              >
                <option value=''>Choose</option>
                <option value='1'>English</option>
                <option value='2'>中文</option>
                <option value='3'>BM</option>
              </select>
            </div>
            <div className='col-md-5 col-sm-6'>
              <label className='form-label'>Remarks</label>
              <input
                type='text'
                className='form-control'
                value={formData.remark || ''}
                onChange={(e) => handleChange('remark', e.target.value)}
              />
            </div>
          </div>
          <div className='row g-3 mt-1'>
            <div className='col-md-4 col-sm-6'>
              <label className='form-label'>
                Registration Options <span className='require'>*</span>
              </label>
              <select
                className='form-control'
                value={formData.packageId || ''}
                onChange={(e) => handleChange('packageId', e.target.value)}
                disabled={formData.status !== 2}
              >
                {packages.map((pkg) => (
                  <option key={pkg._id} value={pkg._id}>
                    {pkg.packageNmEn} - RM {pkg.fee.toFixed(2)}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className='d-flex justify-content-end gap-2 mt-4'>
            <button
              className='btn btn-warning me-3'
              onClick={handleSubmit}
              disabled={submitting}
            >
              {submitting ? 'Updating...' : 'Update'}
            </button>
            <button
              className='btn btn-secondary'
              onClick={() => navigate(`/event/${id}`)}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className='text-danger'>Registration not found</div>
      )}
    </ChurchIndex>
  )
}

export default ChurchRegistrationEditForm
