import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LocalityChangeRequest from './LocalityChangeRequest'

const defaultParticipantForm = {
  searchText: '',
  searchResults: [],
  loading: false,
  gender: '',
  name: '',
  chiName: '',
  email: '',
  phoneNo: '',
  age: '',
  language: '',
  icNo: '',
  remark: '',
  isUserSelected: false,
  isNewRegistration: false,
  originalEmail: '',
  originalPhoneNo: '',
  meeting: false,
  bedCode: '',
}

const ChurchRegistrationStayForm = () => {
  const { id } = useParams()
  const isIntlEventStatic = id === '67cf0e922ea8904cab38ae7b'

  const navigate = useNavigate()

  const [stayPackages, setStayPackages] = useState([])
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [showParticipantForm, setShowParticipantForm] = useState(false)
  const [participantForms, setParticipantForms] = useState([
    defaultParticipantForm,
  ])
  const [selectedParticipant, setSelectedParticipant] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [errors, setErrors] = useState([])

  useEffect(() => {
    const fetchStayPackages = async () => {
      setLoading(true)
      try {
        const response = await axios.get(
          `/api/v1/church/event/register/stay-packages/${id}`
        )
        const { stayInPackages } = response.data
        setStayPackages(stayInPackages)
        if (stayInPackages.length > 0) {
          handlePackageChange(stayInPackages[0]._id, stayInPackages)
        }
      } catch (error) {
        console.error('Error fetching stay-in packages:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchStayPackages()
  }, [id])

  const handlePackageChange = (packageId, packages = stayPackages) => {
    const selected = packages.find((pkg) => pkg._id === packageId)
    if (selected) {
      setSelectedPackage(selected)
      setShowParticipantForm(true)
      setParticipantForms(
        Array(Math.min(selected.capacity, 4)).fill({
          ...defaultParticipantForm,
        })
      )
      setErrors([])
    } else {
      setSelectedPackage(null)
      setShowParticipantForm(false)
      setParticipantForms([])
    }
  }

  const handleSearchChange = (index, text) => {
    setParticipantForms((prevForms) => {
      return prevForms.map((form, i) =>
        i === index
          ? {
              ...form,
              searchText: text,
              loading: text.length > 3,
              isUserSelected: false,
              searchResults: [],
              ...(text.trim() === '' && {
                gender: '',
                name: '',
                chiName: '',
                email: '',
                phoneNo: '',
                age: '',
                language: '',
                icNo: '',
                remark: '',
                originalEmail: '',
                originalPhoneNo: '',
                isNewRegistration: false,
              }),
            }
          : form
      )
    })

    if (text.trim() === '') {
      return
    }

    if (text.length > 3) {
      axios
        .post('/api/v1/church/event/register/search', {
          searchText: text,
          eventId: id,
        })
        .then(({ data }) => {
          setParticipantForms((prevForms) =>
            prevForms.map((form, i) =>
              i === index
                ? {
                    ...form,
                    searchResults: data,
                    loading: false,
                    isNewRegistration: data.length === 0,
                  }
                : form
            )
          )
        })
        .catch((error) => {
          console.error('Error searching users:', error)
          setParticipantForms((prevForms) =>
            prevForms.map((form, i) =>
              i === index
                ? {
                    ...form,
                    loading: false,
                    searchResults: [],
                  }
                : form
            )
          )
        })
    }
  }

  const handleUserSelect = (index, user) => {
    setParticipantForms((prevForms) =>
      prevForms.map((form, i) =>
        i === index
          ? {
              ...form,
              userId: user._id,
              searchText: user.name,
              searchResults: [],
              isUserSelected: true,
              gender: user.gender || '',
              name: user.name || '',
              chiName: user.chiName || '',
              email: user.email || '',
              originalEmail: user.email || '',
              phoneNo: user.phoneNo || '',
              originalPhoneNo: user.phoneNo || '',
              icNo: user.icNo || '',
              age: user.age || '',
              language: user.language || '',
              remark: '',
            }
          : form
      )
    )
  }

  const handleFieldChange = (index, field, value) => {
    setParticipantForms((prevForms) =>
      prevForms.map((form, i) =>
        i === index ? { ...form, [field]: value } : form
      )
    )
    setErrors((prevErrors) =>
      prevErrors.map((err, i) =>
        i === index ? { ...err, [field]: value ? '' : err[field] } : err
      )
    )
  }

  const handleRemove = (index) => {
    setParticipantForms((prevForms) =>
      prevForms.map((form, i) =>
        i === index ? { ...defaultParticipantForm } : form
      )
    )
  }

  const isSubmitEnabled = participantForms.some(
    (form) => form.gender && form.name
  )

  const validateFields = () => {
    let newErrors = participantForms.map((form, index) => {
      const isSpouse =
        isIntlEventStatic && selectedPackage?.spouseInfo === 1 && index === 1

      return form.gender && form.name
        ? {
            gender: form.gender ? '' : 'Required!',
            name: form.name ? '' : 'Required!',
            lastName:
              isIntlEventStatic && !isSpouse && !form.lastName
                ? 'Required!'
                : '',
            phoneNo: form.phoneNo ? '' : 'Required!',
            age: form.age ? '' : 'Required!',
            language: isSpouse ? '' : form.language ? '' : 'Required!',
            icNo: form.icNo ? '' : 'Required!',
          }
        : {}
    })

    setErrors(newErrors)
    return newErrors.every((err) => Object.values(err).every((msg) => !msg))
  }

  const handleSubmit = async () => {
    if (!validateFields() || !isSubmitEnabled) return
    setIsSubmitting(true)

    const updatedParticipants = participantForms.map((form, index) => {
      const emailChanged =
        form.isUserSelected && form.originalEmail !== undefined
          ? form.email !== form.originalEmail
          : false
      const phoneChanged =
        form.isUserSelected && form.originalPhoneNo !== undefined
          ? form.phoneNo !== form.originalPhoneNo
          : false
      const genderChanged = form.gender !== form.originalGender
      const nameChanged = form.name !== form.originalName
      const chiNameChanged = form.chiName !== form.originalChiName
      const icNoChanged = form.icNo !== form.originalIcNo

      const userUpdateRequired =
        emailChanged ||
        phoneChanged ||
        genderChanged ||
        nameChanged ||
        chiNameChanged ||
        icNoChanged

      const updatedUserData = {}
      if (userUpdateRequired) {
        if (genderChanged) updatedUserData.gender = form.gender
        if (nameChanged) updatedUserData.name = form.name
        if (chiNameChanged) updatedUserData.chiName = form.chiName
        if (icNoChanged) updatedUserData.icNo = form.icNo
        if (emailChanged) updatedUserData.email = form.email
        if (phoneChanged) updatedUserData.phoneNo = form.phoneNo
      }

      return {
        ...form,
        emailChanged,
        phoneChanged,
        genderChanged,
        nameChanged,
        chiNameChanged,
        icNoChanged,
        userUpdateRequired,
        updatedUserData,
        index,
      }
    })

    const participantsToCheck = updatedParticipants
      .filter(
        (p) =>
          (p.emailChanged || p.phoneChanged) &&
          (p.isNewRegistration || p.isUserSelected)
      )
      .map(({ userId, email, phoneNo, index }) => ({
        userId,
        email,
        phoneNo,
        index,
      }))

    if (participantsToCheck.length > 0) {
      try {
        const { data } = await axios.post(
          '/api/v1/church/event/register/check-unique-multipleuser',
          { participants: participantsToCheck },
          { withCredentials: true }
        )

        if (data.duplicateUsers?.length > 0) {
          setParticipantForms((prevForms) =>
            prevForms.map((form, i) => {
              const match = data.duplicateUsers.find((dup) => dup.index === i)
              return match
                ? {
                    ...form,
                    uniqueEmailError: match.emailExists ? (
                      <div className='d-flex align-items-center'>
                        <span>
                          Email already exists and belongs to{' '}
                          <b>{match.duplicateUserDetails.name}</b> in{' '}
                          <b>{match.duplicateUserDetails.locality}</b>.
                        </span>
                        {!match.sameLocality && (
                          <button
                            type='button'
                            className='btn btn-sm btn-warning ms-2'
                            onClick={() => handleChangeLocality(i)}
                          >
                            Click here to request a change of saint's locality.
                          </button>
                        )}
                      </div>
                    ) : null,
                    uniquePhoneError: match.phoneExists ? (
                      <div className='d-flex align-items-center'>
                        <span>
                          Phone number already exists and belongs to{' '}
                          <b>{match.duplicateUserDetails.name}</b> in{' '}
                          <b>{match.duplicateUserDetails.locality}</b>.
                        </span>
                        {!match.sameLocality && (
                          <button
                            type='button'
                            className='btn btn-sm btn-warning ms-2'
                            onClick={() => handleChangeLocality(i)}
                          >
                            Click here to request a change of saint's locality.
                          </button>
                        )}
                      </div>
                    ) : null,
                  }
                : form
            })
          )

          setIsSubmitting(false)
          return
        }
      } catch (error) {
        console.error('Error checking uniqueness:', error)
        toast.error('Failed to validate email/phone')
        setIsSubmitting(false)
        return
      }
    }

    try {
      const endpoint = isIntlEventStatic
        ? '/api/v1/church/intl/event/register/local/stay'
        : '/api/v1/church/event/register/stay'

      await axios.post(endpoint, {
        eventId: id,
        packageCapacity: selectedPackage.capacity,
        spouseInfo: selectedPackage.spouseInfo,
        participants: updatedParticipants.map((form) => ({
          userId: form.userId,
          gender: form.gender,
          name: form.name,
          chiName: form.chiName,
          email: form.email,
          phoneNo: form.phoneNo,
          icNo: form.icNo,
          age: form.age,
          language: form.language,
          packageId: selectedPackage._id,
          packageNmEn: selectedPackage.packageNmEn,
          packageNmZh: selectedPackage.packageNmZh,
          packageNmBm: selectedPackage.packageNmBm,
          fee: selectedPackage.fee,
          roomCode: selectedPackage.roomCode,
          completeRoom: participantForms.every(
            (f) =>
              f.gender && f.name && f.phoneNo && f.age && f.language && f.icNo
          )
            ? 1
            : 0,
          remark: form.remark,
          userUpdateRequired: form.userUpdateRequired,
          updatedUserData: form.updatedUserData,
          meeting: form.meeting || false,
          lastName: form.lastName,
          bedCode: form.bedCode,
        })),
      })
      toast.success('Submitted Successfully!')
      resetForm()
    } catch (error) {
      toast.error(error.response?.data.message || 'Failed to register!')
      console.error('Error submitting registration:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const resetForm = () => {
    setParticipantForms(
      Array(selectedPackage.capacity).fill({ ...defaultParticipantForm })
    )
    setErrors([])

    if (stayPackages.length > 0) {
      handlePackageChange(stayPackages[0]._id, stayPackages)
    }
  }

  const handleChangeLocality = (index) => {
    const participant = participantForms[index]
    setSelectedParticipant({
      index,
      userPhone: participant.phoneNo,
      userEmail: participant.email,
    })
    setShowModal(true)
  }

  const resetFormAndErrors = () => {
    setErrors({})
    setShowModal(false)
    setSelectedParticipant(null)
  }

  const listContainerStyle = {
    maxHeight: '200px',
    overflowY: 'auto',
    border: '1px solid #ddd',
    borderRadius: '5px',
    marginRight: '60px',
  }

  return (
    <div className='mb-3'>
      <div className='row mb-3'>
        <div className='col-md-12'>
          <label className='form-label'>Select Stay-In Option</label>
          <select
            className='form-control'
            value={selectedPackage ? selectedPackage._id : ''}
            onChange={(e) => handlePackageChange(e.target.value)}
          >
            {stayPackages.map((pkg) => (
              <option key={pkg._id} value={pkg._id}>
                {pkg.packageNmEn} - RM {pkg.fee.toFixed(2)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='mb-2' style={{ fontWeight: '600' }}>
        <span className='require'>*</span> are required to be filled in.
      </div>

      {loading ? (
        <div
          style={{
            textAlign: 'center',
            color: '#5d3104',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        >
          <span className='spinner-border spinner-border-sm me-2'></span>
          Loading...
        </div>
      ) : (
        <>
          {showParticipantForm && participantForms.length > 0 && (
            <div>
              {participantForms
                .filter((_, index) => {
                  if (isIntlEventStatic && selectedPackage?.spouseInfo === 1) {
                    return index === 0
                  }
                  return true
                })
                .map((form, index) => (
                  <div
                    key={index}
                    className='p-3 mb-3 rounded bg-white shadow-sm'
                    style={{
                      border: '1px solid #a89163',
                      boxShadow: '0 0 10px rgba(217, 83, 79, 0.5)',
                    }}
                  >
                    {participantForms.length > 1 && (
                      <h6 className='fw-bold mb-2' style={{ color: '#834506' }}>
                        Participant {index + 1}{' '}
                      </h6>
                    )}

                    <div className='mb-3'>
                      <div className='col-md-12'>
                        <div className='mb-2' style={{ fontWeight: '600' }}>
                          Enter a name, email, or phone number and choose a
                          participant from the list.
                        </div>

                        <div className='d-flex align-items-center'>
                          <input
                            type='text'
                            className='form-control'
                            value={form.searchText}
                            onChange={(e) =>
                              handleSearchChange(index, e.target.value)
                            }
                          />
                          <button
                            type='button'
                            className={`btn ${
                              form.searchText || form.isUserSelected
                                ? 'btn-warning'
                                : 'btn-secondary'
                            }`}
                            onClick={() => handleRemove(index)}
                            disabled={
                              !form.searchText &&
                              !form.isUserSelected &&
                              !form.isNewRegistration
                            }
                          >
                            Remove
                          </button>
                        </div>

                        {form.isNewRegistration && !form.isUserSelected && (
                          <div
                            className='mt-1 text-danger'
                            style={{ fontWeight: 600 }}
                          >
                            User not found - Please fill in the form below
                          </div>
                        )}

                        {form.searchResults.length > 0 && (
                          <div style={listContainerStyle}>
                            <ul className='list-group'>
                              {form.searchResults.map((user) => (
                                <li
                                  key={user._id}
                                  className={`list-group-item list-group-item-action ${
                                    user.isRegistered ? 'disabled' : ''
                                  }`}
                                  onClick={() =>
                                    !user.isRegistered &&
                                    handleUserSelect(index, user)
                                  }
                                  style={{
                                    cursor: user.isRegistered
                                      ? 'not-allowed'
                                      : 'pointer',
                                  }}
                                >
                                  {user.name}{' '}
                                  {user.isRegistered && (
                                    <span className='text-primary'>
                                      - Registered
                                    </span>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>

                    {(form.uniqueEmailError || form.uniquePhoneError) && (
                      <div
                        className='alert alert-danger p-2 mb-2'
                        style={{ backgroundColor: '#fcedee' }}
                      >
                        {form.uniqueEmailError && (
                          <div>{form.uniqueEmailError}</div>
                        )}
                        {form.uniquePhoneError && (
                          <div>{form.uniquePhoneError}</div>
                        )}
                      </div>
                    )}

                    <div className='row g-3'>
                      <div className='col-md-1 col-sm-6'>
                        <label className='form-label'>
                          Gender <span className='require'>*</span>
                        </label>
                        <select
                          className={`form-control ${
                            errors[index]?.gender ? 'is-invalid' : ''
                          }`}
                          value={form.gender}
                          disabled={
                            !form.isUserSelected && !form.isNewRegistration
                          }
                          onChange={(e) =>
                            handleFieldChange(index, 'gender', e.target.value)
                          }
                        >
                          <option value=''>Choose</option>
                          <option value='1'>Brother</option>
                          <option value='2'>Sister</option>
                        </select>
                        {errors[index]?.gender && (
                          <div className='invalid-feedback'>
                            {errors[index].gender}
                          </div>
                        )}
                      </div>

                      {isIntlEventStatic ? (
                        <>
                          <div className='col-md-2 col-sm-6'>
                            <label className='form-label'>
                              First Name <span className='require'>*</span>
                            </label>
                            <input
                              type='text'
                              className={`form-control ${
                                errors[index]?.name ? 'is-invalid' : ''
                              }`}
                              value={form.name}
                              disabled={
                                !form.isUserSelected && !form.isNewRegistration
                              }
                              onChange={(e) =>
                                handleFieldChange(index, 'name', e.target.value)
                              }
                            />
                            {errors[index]?.name && (
                              <div className='invalid-feedback'>
                                {errors[index].name}
                              </div>
                            )}
                          </div>

                          <div className='col-md-1 col-sm-6'>
                            <label className='form-label'>
                              Last Name <span className='require'>*</span>
                            </label>
                            <input
                              type='text'
                              className={`form-control ${
                                errors[index]?.lastName ? 'is-invalid' : ''
                              }`}
                              value={form.lastName || ''}
                              disabled={
                                !form.isUserSelected && !form.isNewRegistration
                              }
                              onChange={(e) =>
                                handleFieldChange(
                                  index,
                                  'lastName',
                                  e.target.value
                                )
                              }
                            />
                            {errors[index]?.lastName && (
                              <div className='invalid-feedback'>
                                {errors[index].lastName}
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className='col-md-3 col-sm-6'>
                          <label className='form-label'>
                            English Name <span className='require'>*</span>
                          </label>
                          <input
                            type='text'
                            className={`form-control ${
                              errors[index]?.name ? 'is-invalid' : ''
                            }`}
                            value={form.name}
                            disabled={
                              !form.isUserSelected && !form.isNewRegistration
                            }
                            onChange={(e) =>
                              handleFieldChange(index, 'name', e.target.value)
                            }
                          />
                          {errors[index]?.name && (
                            <div className='invalid-feedback'>
                              {errors[index].name}
                            </div>
                          )}
                        </div>
                      )}

                      <div className='col-md-3 col-sm-6'>
                        <label className='form-label'>Chinese Name</label>
                        <input
                          type='text'
                          className='form-control'
                          value={form.chiName}
                          disabled={
                            !form.isUserSelected && !form.isNewRegistration
                          }
                          onChange={(e) =>
                            handleFieldChange(index, 'chiName', e.target.value)
                          }
                        />
                      </div>
                      <div className='col-md-3 col-sm-6'>
                        <label className='form-label'>Email</label>
                        <input
                          type='email'
                          className='form-control'
                          value={form.email}
                          disabled={
                            !form.isUserSelected && !form.isNewRegistration
                          }
                          onChange={(e) =>
                            handleFieldChange(index, 'email', e.target.value)
                          }
                        />
                      </div>
                      <div className='col-md-2 col-sm-6'>
                        <label className='form-label'>
                          Phone Number <span className='require'>*</span>
                        </label>
                        <input
                          type='text'
                          className={`form-control ${
                            errors[index]?.phoneNo ? 'is-invalid' : ''
                          }`}
                          value={form.phoneNo}
                          disabled={
                            !form.isUserSelected && !form.isNewRegistration
                          }
                          onChange={(e) =>
                            handleFieldChange(index, 'phoneNo', e.target.value)
                          }
                        />
                        {errors[index]?.phoneNo && (
                          <div className='invalid-feedback'>
                            {errors[index].phoneNo}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row g-3 mt-1'>
                      <div className='col-md-1 col-sm-6'>
                        <label className='form-label'>
                          Age <span className='require'>*</span>
                        </label>
                        <input
                          type='number'
                          className={`form-control ${
                            errors[index]?.age ? 'is-invalid' : ''
                          }`}
                          value={form.age}
                          disabled={
                            !form.isUserSelected && !form.isNewRegistration
                          }
                          onChange={(e) =>
                            handleFieldChange(index, 'age', e.target.value)
                          }
                        />
                        {errors[index]?.age && (
                          <div className='invalid-feedback'>
                            {errors[index].age}
                          </div>
                        )}
                      </div>
                      <div className='col-md-3 col-sm-6'>
                        <label className='form-label'>
                          I.C/Passport <span className='require'>*</span>
                        </label>
                        <input
                          type='text'
                          className={`form-control ${
                            errors[index]?.icNo ? 'is-invalid' : ''
                          }`}
                          value={form.icNo}
                          disabled={
                            !form.isUserSelected && !form.isNewRegistration
                          }
                          onChange={(e) =>
                            handleFieldChange(index, 'icNo', e.target.value)
                          }
                        />
                        {errors[index]?.icNo && (
                          <div className='invalid-feedback'>
                            {errors[index].icNo}
                          </div>
                        )}
                      </div>
                      <div className='col-md-3 col-sm-6'>
                        <label className='form-label'>
                          Language <span className='require'>*</span>
                        </label>
                        <select
                          className={`form-control ${
                            errors[index]?.language ? 'is-invalid' : ''
                          }`}
                          value={form.language}
                          disabled={
                            !form.isUserSelected && !form.isNewRegistration
                          }
                          onChange={(e) =>
                            handleFieldChange(index, 'language', e.target.value)
                          }
                        >
                          <option value=''>Choose</option>
                          <option value='1'>English</option>
                          <option value='2'>中文</option>
                          <option value='3'>BM</option>
                        </select>
                        {errors[index]?.language && (
                          <div className='invalid-feedback'>
                            {errors[index].language}
                          </div>
                        )}
                      </div>

                      <div className='col-md-5 col-sm-6'>
                        <label className='form-label'>Remarks</label>
                        <input
                          type='text'
                          className='form-control'
                          value={form.remark}
                          disabled={
                            !form.isUserSelected && !form.isNewRegistration
                          }
                          onChange={(e) =>
                            handleFieldChange(index, 'remark', e.target.value)
                          }
                        />
                      </div>
                    </div>

                    {(selectedPackage?.bedOption?.length > 0 ||
                      isIntlEventStatic) && (
                      <div className='row mt-3 align-items-center'>
                        {/* Bed Option */}
                        {selectedPackage.bedOption?.length > 0 &&
                          (selectedPackage.spouseInfo === 1
                            ? index === 0
                            : true) && (
                            <div className='col-md-2 col-sm-12'>
                              <label className='form-label'>Bed Option</label>
                              <select
                                className='form-control'
                                value={form.bedCode || ''}
                                onChange={(e) =>
                                  handleFieldChange(
                                    index,
                                    'bedCode',
                                    e.target.value
                                  )
                                }
                                disabled={
                                  !form.isUserSelected &&
                                  !form.isNewRegistration
                                }
                              >
                                <option value=''>Choose</option>
                                {selectedPackage.bedOption.map((opt) => (
                                  <option key={opt.bedCode} value={opt.bedCode}>
                                    {opt.bedName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}

                        {/* Meeting Checkbox */}
                        {isIntlEventStatic && (
                          <div
                            className={
                              selectedPackage?.bedOption?.length > 0
                                ? 'col-md-10 col-sm-12'
                                : 'col-md-12 col-sm-12'
                            }
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-end',
                              gap: '8px',
                              fontWeight: '600',
                            }}
                          >
                            <input
                              type='checkbox'
                              id={`blendingMeeting-${index}`}
                              checked={form.meeting || false}
                              onChange={(e) =>
                                handleFieldChange(
                                  index,
                                  'meeting',
                                  e.target.checked
                                )
                              }
                              style={{
                                transform: 'scale(1.3)',
                                cursor: 'pointer',
                              }}
                            />
                            <label
                              className='me-2'
                              htmlFor={`blendingMeeting-${index}`}
                              style={{ cursor: 'pointer' }}
                            >
                              Attending Lord's Day Morning Blending Meeting
                            </label>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}

              <div className='d-flex justify-content-end mt-4'>
                <button
                  className='btn btn-primary me-3'
                  onClick={handleSubmit}
                  disabled={!isSubmitEnabled || isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <span className='spinner-border spinner-border-sm me-2'></span>
                      Submitting...
                    </>
                  ) : (
                    'Submit'
                  )}
                </button>
                <button
                  className='btn btn-outline-secondary'
                  onClick={() => navigate(`/event/${id}`)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {showModal && selectedParticipant && (
        <LocalityChangeRequest
          visible={showModal}
          onClose={() => setShowModal(false)}
          userPhone={selectedParticipant.userPhone}
          userEmail={selectedParticipant.userEmail}
          resetForm={() => {
            setParticipantForms((prevForms) =>
              prevForms.map((form, i) =>
                i === selectedParticipant.index
                  ? { ...defaultParticipantForm }
                  : form
              )
            )
            resetFormAndErrors()
          }}
        />
      )}
    </div>
  )
}

export default ChurchRegistrationStayForm
