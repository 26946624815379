import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import PrivateRoute from './components/Routes/PrivateRoute'
import AdminRoute from './components/Routes/AdminRoute'
import ChurchRoute from './components/Routes/ChurchRoute'
import IntlRoute from './components/Routes/IntlRoute'
import { EventProvider } from './context/event'

import Login from './pages/Auth/Login'
import IntlLogin from './pages/Auth/IntlLogin'
import ResetPasswordRequest from './pages/Auth/ResetPasswordRequest'

// Admin
import AdminDashboard from './pages/Admin/Dashboard'
import AdminUserList from './pages/Admin/User/UserList'
import AdminEditUser from './pages/Admin/User/EditUser'
import AdminBankList from './pages/Admin/Event/Bank/BankList'
import AdminEventList from './pages/Admin/Event/Data/EventList'
import AdminNewEvent from './pages/Admin/Event/Data/NewEvent'
import AdminEditEvent from './pages/Admin/Event/Data/EditEvent'
import AdminAnnounceList from './pages/Admin/Announcement/AnnounceList'
import AdminNewAnnounce from './pages/Admin/Announcement/NewAnnounce'
import AdminPaymentEventRecords from './pages/Admin/Registration/Payment'
import AdminLocalityIndex from './pages/Admin/Locality'
import AdminMigrate from './pages/Admin/AdminMigrate'
import AdminTransportRecords from './pages/Admin/Registration/Transport'
import AdminRegistrationData from './pages/Admin/Registration/Data'
import AdminAddEventRegister from './pages/Admin/Registration/AddEventRegister'

// Church
import ChurchIndex from './pages/Church/Index'
import ChurchRegistrationFormIndex from './pages/Church/Registration/Index'
import ChurchTransportIndex from './pages/Church/Transport/Index'
import ChurchPaymentIndex from './pages/Church/Payment/Index'

// Intl
import IntlRegistrationList from './pages/Intl/List'
import IntlRegistrationFormIndex from './pages/Intl/Registration/Index'

// General
import PageNotFound from './pages/PageNotFound'
import UserRoute from './components/Routes/UserRoute'
import MyAccount from './pages/User/Account'
import AdminOnlineRegistrationData from './pages/Admin/Online/Data'
import ResetPassword from './pages/Auth/ResetPassword'
import IntlRouteSwitch from './components/Routes/IntlRouteSwitch'
import IntlRegisterEdit from './pages/Intl/Individual/RegisterEdit'
import IntlIndividualTransportEditForm from './pages/Intl/Individual/TransportEdit'
import TawkMessenger from './components/shared/TawkMessenger'
import IntlGroupRegisterEdit from './pages/Intl/Registration/Edit'
import ChurchRegistrationEditForm from './pages/Church/Registration/EditForm'
import ChurchEditTransport from './pages/Church/Transport/EditTransport'

function App() {
  const { pathname } = useLocation()
  const showChat = pathname.startsWith('/itero/hotel-reservation')

  return (
    <>
      {showChat && <TawkMessenger />}
      <Routes>
        {/* Redirect old url */}
        <Route path='/en/login' element={<Navigate to='/' replace />} />
        {/* Public Routes */}
        <Route path='/login' element={<Login />} />
        <Route path='/itero/hotel-reservation/login' element={<IntlLogin />} />
        <Route
          path='/reset-password-request'
          element={<ResetPasswordRequest />}
        />
        <Route path='/reset-password' element={<ResetPassword />} />

        {/* Protected Routes */}
        <Route path='/' element={<PrivateRoute />} />

        {/* Admin Routes */}
        <Route path='/admin' element={<AdminRoute />}>
          <Route index element={<Navigate to='dashboard' replace />} />
          <Route path='dashboard' element={<AdminDashboard />} />
          {/* Registration Info Routes */}
          {/* Physical Meeting */}
          <Route
            path='registration/data/:id'
            element={<AdminRegistrationData />}
          />
          <Route
            path='registration/new/:id'
            element={<AdminAddEventRegister />}
          />
          <Route
            path='registration/payment/:id'
            element={<AdminPaymentEventRecords />}
          />
          <Route
            path='registration/transport/:id'
            element={<AdminTransportRecords />}
          />
          {/* Online Meeting */}
          <Route
            path='registration/online/data/:id'
            element={<AdminOnlineRegistrationData />}
          />
          <Route path='announcement' element={<AdminAnnounceList />} />
          <Route path='announcement/add' element={<AdminNewAnnounce />} />
          <Route path='personal-account' element={<AdminUserList />} />
          <Route path='personal-account/edit/:id' element={<AdminEditUser />} />
          <Route path='events' element={<AdminEventList />} />
          <Route path='events/add' element={<AdminNewEvent />} />
          <Route path='event/edit/:eventId' element={<AdminEditEvent />} />
          <Route path='bank-account' element={<AdminBankList />} />
          <Route path='church-account' element={<AdminLocalityIndex />} />
          <Route path='migrate' element={<AdminMigrate />} />
        </Route>

        {/* Church Routes */}
        <Route path='/event' element={<ChurchRoute />}>
          <Route
            path=':id'
            element={
              <EventProvider>
                <ChurchIndex />
              </EventProvider>
            }
          />
          <Route
            path=':id/registration'
            element={
              <EventProvider>
                <ChurchRegistrationFormIndex />
              </EventProvider>
            }
          />
          <Route
            path=':id/registration/edit/:registrationId'
            element={
              <EventProvider>
                <ChurchRegistrationEditForm />
              </EventProvider>
            }
          />
          <Route
            path=':id/transportation'
            element={
              <EventProvider>
                <ChurchTransportIndex />
              </EventProvider>
            }
          />
          <Route
            path=':id/transportation/edit/:transportId'
            element={
              <EventProvider>
                <ChurchEditTransport />
              </EventProvider>
            }
          />
          <Route
            path=':id/payment'
            element={
              <EventProvider>
                <ChurchPaymentIndex />
              </EventProvider>
            }
          />
        </Route>

        {/* Intl Routes */}
        <Route path='/itero/hotel-reservation' element={<IntlRoute />}>
          <Route path='registration' element={<IntlRegistrationList />} />
          <Route path='form' element={<IntlRegistrationFormIndex />} />
          <Route path='edit' element={<IntlRegisterEdit />} />
          {/* Group */}
          <Route path='group/edit/:id' element={<IntlGroupRegisterEdit />} />

          <Route
            path='transportation'
            element={<IntlRouteSwitch type='transportation' />}
          />
          <Route
            path='transportation/edit'
            element={<IntlIndividualTransportEditForm />}
          />

          <Route path='payment' element={<IntlRouteSwitch type='payment' />} />
        </Route>

        {/* User Routes */}
        <Route path='/personal' element={<UserRoute />}>
          <Route path='account' element={<MyAccount />} />
        </Route>

        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </>
  )
}

export default App
