import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Modal } from 'antd'
import axios from 'axios'
import { toast } from 'react-toastify'
import ChurchNewTransport from './NewTransport'
import ChurchIndex from '../Index'
import { useEvent } from '../../../context/event'
import LoadingSpinner from '../../../components/shared/LoadingSpinner'

const ChurchTransportList = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { event, loading: eventLoading } = useEvent()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [transportRecords, setTransportRecords] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchTransportRecords()
  }, [])

  const fetchTransportRecords = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `/api/v1/church/transport/records/${id}`,
        { withCredentials: true }
      )
      if (response.data.success) {
        setTransportRecords(response.data.data)
      }
    } catch (error) {
      console.error('Error fetching transport records:', error)
    } finally {
      setLoading(false)
    }
  }

  const showModal = async () => {
    setIsModalOpen(true)
  }

  const handleAddSuccess = (newRecords) => {
    setTransportRecords((prevRecords) => [...newRecords, ...prevRecords])
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Confirm Delete?')
    if (!confirmDelete) return

    try {
      const response = await axios.delete(
        `/api/v1/church/transport/records/${id}`,
        { withCredentials: true }
      )
      if (response.data.success) {
        setTransportRecords((prevRecords) =>
          prevRecords.filter((record) => record._id !== id)
        )
        toast.success('Record deleted successfully')
      }
    } catch (error) {
      console.error('Error deleting record:', error)
      toast.error('Failed to delete record')
    }
  }

  return (
    <ChurchIndex>
      {eventLoading || !event ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className='d-flex justify-content-end gap-4 mb-2'>
            <button
              className='btn btn-success btn-sm'
              style={{ textTransform: 'uppercase', fontWeight: 600 }}
              onClick={() => navigate(`/event/${id}`)}
            >
              Registration List
            </button>
            <button
              className='btn btn-success btn-sm'
              style={{ textTransform: 'uppercase' }}
              onClick={() => navigate(`/event/${id}/registration`)}
              disabled={event.regTypeStatus !== 1}
            >
              Registration Form
            </button>
            <button
              className='btn btn-success btn-sm'
              style={{ textTransform: 'uppercase', fontWeight: 600 }}
              onClick={() => navigate(`/event/${id}/payment`)}
            >
              Payment
            </button>
            <button
              className='btn btn-success btn-sm'
              disabled
              style={{
                textTransform: 'uppercase',
                fontWeight: 600,
                cursor: 'not-allowed',
                paddingTop: '6px',
                paddingBottom: '6px',
              }}
            >
              Transportation
            </button>
          </div>

          <div
            className='fw-bold text-center mb-2'
            style={{
              fontSize: '17px',
              color: '#219119',
              textTransform: 'uppercase',
            }}
          >
            Transportation
          </div>

          <div className='d-flex justify-content-between align-items-center mb-2'>
            <div className='ms-auto'>
              <button className='btn btn-primary btn-sm' onClick={showModal}>
                Add Transportation
              </button>
            </div>
          </div>

          <div className='table-responsive mb-3'>
            <table className='table'>
              <thead>
                <tr>
                  <th rowSpan='2' scope='col'>
                    Name
                  </th>
                  <th colSpan='3' className='text-center' scope='col'>
                    Arrival
                  </th>
                  <th colSpan='3' className='text-center' scope='col'>
                    Departure
                  </th>
                  <th rowSpan='2' scope='col' className='text-end'>
                    Created At
                  </th>
                  <th rowSpan='2' scope='col'></th>
                </tr>
                <tr>
                  <th scope='col'>Date</th>
                  <th className='text-center' scope='col'>
                    Time
                  </th>
                  <th scope='col'>Flight No./Bus</th>
                  <th scope='col'>Date</th>
                  <th className='text-center' scope='col'>
                    Time
                  </th>
                  <th scope='col'>Flight No./Bus</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan='10' className='text-center'>
                      Loading...
                    </td>
                  </tr>
                ) : transportRecords.length > 0 ? (
                  transportRecords.map((record) => (
                    <React.Fragment key={record._id}>
                      <tr
                        style={{
                          borderColor: record.remark ? 'white' : '',
                        }}
                      >
                        <td>
                          <div
                            className='fw-bold'
                            style={{
                              textTransform: 'uppercase',
                              color:
                                record.gender === 1 ? '#2f4483' : '#78003c',
                            }}
                          >
                            {record.name}
                          </div>
                          {record.chiName && <div>{record.chiName}</div>}
                        </td>
                        <td>
                          {record.arrivalDate
                            ? new Date(record.arrivalDate).toLocaleDateString(
                                'en-GB',
                                {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                }
                              )
                            : ''}
                        </td>
                        <td className='text-center'>
                          {record.arrivalTime || ''}
                        </td>
                        <td>{record.arrivalInfo || ''}</td>
                        <td>
                          {record.departDate
                            ? new Date(record.departDate).toLocaleDateString(
                                'en-GB',
                                {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                }
                              )
                            : ''}
                        </td>
                        <td className='text-center'>
                          {record.departTime || ''}
                        </td>
                        <td>{record.departInfo || ''}</td>

                        <td className='text-end'>
                          {new Date(record.createdAt).toLocaleDateString(
                            'en-GB',
                            {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                            }
                          )}
                          <div>
                            {new Date(record.createdAt).toLocaleTimeString(
                              'en-GB',
                              {
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: false,
                              }
                            )}
                          </div>
                        </td>

                        <td className='text-end'>
                          <i className='bi bi-pencil-square text-primary'></i>
                          <div>
                            <i
                              className='bi bi-trash3 text-danger ms-3'
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleDelete(record._id)}
                            ></i>
                          </div>
                        </td>
                      </tr>
                      {record.remark && (
                        <tr>
                          <td colSpan='10' className='pt-0'>
                            <span className='me-2' style={{ fontWeight: 600 }}>
                              Remark:
                            </span>
                            {record.remark}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan='10' className='text-center'>
                      No Records
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            width={900}
            destroyOnClose
          >
            <ChurchNewTransport
              id={id}
              isModalOpen={isModalOpen}
              onCancel={handleCancel}
              onAddSuccess={handleAddSuccess}
            />
          </Modal>
        </>
      )}
    </ChurchIndex>
  )
}

export default ChurchTransportList
