import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import PaginationButtons from '../../components/shared/PaginationButtons'
import { Tooltip } from 'antd'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LoadingSpinner from '../../components/shared/LoadingSpinner'

const ChurchRegistrationList = ({ event }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const isIntlEventStatic = id === '67cf0e922ea8904cab38ae7b'

  const [records, setRecords] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [summary, setSummary] = useState({
    totalRegistered: 0,
    totalPaid: 0,
    totalUnpaid: 0,
    totalFee: 0,
    totalPaidFee: 0,
    totalUnpaidFee: 0,
  })
  const formatNumber = (num) =>
    num.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

  const fetchSummary = async () => {
    try {
      const response = await axios.get(
        isIntlEventStatic
          ? '/api/v1/church/intl/event/record/summary'
          : `/api/v1/church/event/record/summary/${id}`,
        { withCredentials: true }
      )

      setSummary(response.data)
    } catch (error) {
      console.error('Error fetching registration summary:', error)
    }
  }

  const fetchRegistrationRecords = async () => {
    try {
      setLoading(true)
      const response = await axios.get(
        isIntlEventStatic
          ? '/api/v1/church/intl/event/record'
          : `/api/v1/church/event/record/${id}`,
        {
          params: { page: currentPage, search: searchQuery },
          withCredentials: true,
        }
      )
      setRecords(response.data.data.registrations)
      setTotalPages(response.data.data.pagination.totalPages)
    } catch (error) {
      console.error('Error fetching registration records:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (searchQuery.length > 3 || searchQuery === '') {
      fetchRegistrationRecords()
    }
  }, [id, currentPage, searchQuery])

  useEffect(() => {
    if (currentPage === 1) {
      fetchSummary()
    }
  }, [currentPage])

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
    setCurrentPage(1)
  }

  const handleClearSearch = () => {
    setSearchQuery('')
  }

  const handleExport = async () => {
    try {
      if (!event) {
        console.error('Event details not available for export')
        return
      }

      const eventName = event.nameEn
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase())
        .replace(/\s+/g, '_')

      const response = await axios.get(
        `/api/v1/church/event/record/export/${id}`,
        {
          withCredentials: true,
          responseType: 'blob',
        }
      )

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      })

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${eventName}.xlsx`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error('Error exporting registrations:', error)
    }
  }

  const handleEditClick = (registrationId) => {
    navigate(`/event/${id}/registration/edit/${registrationId}`)
  }

  const handleDelete = async (recordId) => {
    const confirmDelete = window.confirm('Confirm Delete?')
    if (!confirmDelete) return

    // Find the record from the current records list
    const targetRecord = records.find((record) => record._id === recordId)
    const refetchData = targetRecord && targetRecord.completeRoom === 1

    try {
      const deleteUrl = isIntlEventStatic
        ? `/api/v1/church/intl/event/record/${recordId}`
        : `/api/v1/church/event/record/${id}/${recordId}`

      await axios.delete(deleteUrl)

      if (refetchData) {
        toast.success('Record deleted successfully!')
        // Re-fetch all records because pairing/room affects others
        await fetchRegistrationRecords()
      } else {
        setRecords((prevRecords) =>
          prevRecords.filter((record) => record._id !== recordId)
        )
        toast.success('Record deleted successfully!')
        fetchSummary()
      }
    } catch (error) {
      console.error('Error deleting registration:', error)
      toast.error('Failed to delete record!')
    }
  }

  return (
    <>
      {event.regTypeStatus !== 1 && (
        <div className='text-center fw-bold mb-3'>
          <span>
            <i className='bi bi-info-circle-fill text-danger'></i> Registration
            is Closed. If you want to register, please email us at
          </span>{' '}
          <a href='mailto:registration@localchurch.my'>
            registration@localchurch.my
          </a>
        </div>
      )}

      <div className='d-flex justify-content-end gap-4 mb-3'>
        <button
          className='btn btn-success btn-sm'
          disabled
          style={{
            textTransform: 'uppercase',
            fontWeight: 600,
            cursor: 'not-allowed',
            paddingTop: '6px',
            paddingBottom: '6px',
          }}
        >
          Registration List
        </button>
        <button
          className='btn btn-success btn-sm'
          style={{ textTransform: 'uppercase' }}
          onClick={() => navigate('registration')}
          disabled={event.regTypeStatus !== 1}
        >
          Registration Form
        </button>
        <button
          className='btn btn-success btn-sm'
          style={{ textTransform: 'uppercase' }}
          onClick={() => navigate('payment')}
        >
          Payment
        </button>

        <button
          className='btn btn-success btn-sm'
          style={{ textTransform: 'uppercase' }}
          onClick={() => navigate('transportation')}
        >
          Transportation
        </button>
      </div>

      <div
        className='fw-bold text-center mb-2'
        style={{
          fontSize: '17px',
          color: '#219119',
          textTransform: 'uppercase',
        }}
      >
        Registration List
      </div>

      <div className='d-flex justify-content-between align-items-center mb-2'>
        <div className='fw-bold'>
          Total - {summary.totalRegistered.toLocaleString('en-US')} (RM{' '}
          {formatNumber(summary.totalFee)}) |{' '}
          <span style={{ color: '#1e7e34' }}>
            Paid/Processing - {summary.totalPaid.toLocaleString('en-US')} (RM{' '}
            {formatNumber(summary.totalPaidFee)})
          </span>{' '}
          |{' '}
          <span style={{ color: 'red' }}>
            Unpaid - {summary.totalUnpaid.toLocaleString('en-US')} (RM{' '}
            {formatNumber(summary.totalUnpaidFee)})
          </span>
        </div>

        <div className='d-flex align-items-center'>
          <div style={{ width: '300px' }}>
            <input
              type='text'
              className='form-control'
              placeholder='Search Name / Email / Phone'
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>

          <button className='btn btn-secondary' onClick={handleClearSearch}>
            Clear
          </button>
          {!isIntlEventStatic && (
            <Tooltip title='Download Registration List'>
              <button
                className='btn btn-info ms-3'
                onClick={handleExport}
                style={{ whiteSpace: 'nowrap' }}
              >
                <i className='bi bi-file-earmark-spreadsheet'></i>
              </button>
            </Tooltip>
          )}
        </div>
      </div>

      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>{isIntlEventStatic ? 'First / Last Name' : 'Name'}</th>
              <th>Email/Phone</th>
              <th style={{ whiteSpace: 'nowrap' }}>Age (I.C./Passport)</th>
              <th>Language</th>
              <th>Registration</th>
              <th className='text-end'>Amount</th>
              <th className='text-center'>Status</th>
              <th className='text-end' style={{ whiteSpace: 'nowrap' }}>
                Register Date
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan='9' className='text-center'>
                  <LoadingSpinner />
                </td>
              </tr>
            ) : records.length > 0 ? (
              [...records]
                .sort((a, b) => {
                  const aRegistered = a.status && a.status !== 0
                  const bRegistered = b.status && b.status !== 0
                  return aRegistered - bRegistered
                })
                .map((reg, index) => (
                  <React.Fragment key={reg._id}>
                    <tr
                      style={{
                        borderColor:
                          reg.remark || reg.pairingName || reg.meeting
                            ? 'white'
                            : '',
                      }}
                    >
                      <td>
                        <div
                          className='fw-bold'
                          style={{
                            textTransform: 'uppercase',
                            color: reg.gender === 1 ? '#2f4483' : '#78003c',
                          }}
                        >
                          {isIntlEventStatic
                            ? `${reg.name} ${reg.lastName || ''}`
                            : reg.name}
                        </div>
                        {reg.chiName && <div>{reg.chiName}</div>}
                      </td>
                      <td>
                        {reg.email && !reg.email.includes('@empty') && (
                          <div style={{ whiteSpace: 'nowrap' }}>
                            <i
                              className='bi bi-envelope-fill me-2'
                              style={{ color: '#7a6844' }}
                            ></i>
                            {reg.email}
                          </div>
                        )}
                        {reg.phoneNo && (
                          <div style={{ whiteSpace: 'nowrap' }}>
                            <i
                              className='bi bi-telephone-fill me-2'
                              style={{ color: '#7a6844' }}
                            ></i>
                            {reg.phoneNo}
                          </div>
                        )}
                      </td>
                      <td>
                        {reg.age} {reg.icNo ? `(${reg.icNo})` : ''}
                      </td>
                      <td>{['', 'English', '中文', 'BM'][reg.language]}</td>

                      <td>
                        {reg.packageNmEn} {reg.roomCode} {reg.roomNo}
                        {reg.isChildren === 1 && (
                          <div style={{ fontWeight: '600', color: '#4cae4c' }}>
                            {reg.parentName}
                          </div>
                        )}
                      </td>
                      <td className='text-end'>{Number(reg.fee).toFixed(2)}</td>
                      <td className='text-center'>
                        <Tooltip
                          title={
                            reg.status === 1
                              ? 'Paid & Confirmed'
                              : reg.status === 2
                              ? 'Pending Payment'
                              : 'Processing'
                          }
                        >
                          <span
                            style={{
                              display: 'inline-block',
                              width: '14px',
                              height: '14px',
                              borderRadius: '50%',
                              backgroundColor:
                                reg.status === 1
                                  ? '#1e7e34'
                                  : reg.status === 2
                                  ? 'red'
                                  : '#ba8b00',
                              marginRight: '8px',
                              cursor: 'pointer',
                            }}
                          ></span>
                        </Tooltip>
                      </td>
                      <td className='text-end'>
                        {(() => {
                          const dateObj = new Date(reg.createdAt)
                          const formattedDate = dateObj.toLocaleDateString(
                            'en-GB',
                            {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                            }
                          )
                          const formattedTime = dateObj
                            .toISOString()
                            .slice(11, 19)
                          return (
                            <div>
                              <div>{formattedDate}</div>
                              <div>{formattedTime}</div>
                            </div>
                          )
                        })()}
                      </td>
                      <td className='text-end'>
                        {/* <i
                          className='bi bi-pencil-square text-primary'
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleEditClick(reg._id)}
                        ></i> */}
                        <div>
                          <i
                            className={`bi bi-trash3 ms-3 ${
                              reg.status === 2
                                ? 'text-danger'
                                : 'text-secondary'
                            }`}
                            style={{
                              cursor:
                                reg.status !== 2 ? 'not-allowed' : 'pointer',
                              pointerEvents: reg.status !== 2 ? 'none' : 'auto',
                            }}
                            onClick={
                              reg.status === 2
                                ? () => handleDelete(reg._id)
                                : undefined
                            }
                          ></i>
                        </div>
                      </td>
                    </tr>
                    {reg.pairingName && (
                      <tr
                        style={{
                          borderColor: reg.remark || reg.meeting ? 'white' : '',
                        }}
                      >
                        <td colSpan='9' className='pt-0'>
                          <span className='me-2' style={{ fontWeight: 600 }}>
                            Pair with:
                          </span>
                          <span style={{ textTransform: 'uppercase' }}>
                            {reg.pairingName}
                          </span>
                        </td>
                      </tr>
                    )}
                    {reg.remark && (
                      <tr
                        style={{
                          borderColor: reg.meeting ? 'white' : '',
                        }}
                      >
                        <td colSpan='9' className='pt-0'>
                          <span className='me-2' style={{ fontWeight: 600 }}>
                            Remark:
                          </span>
                          {reg.remark}
                        </td>
                      </tr>
                    )}
                    {isIntlEventStatic && reg.meeting === 1 && (
                      <tr>
                        <td colSpan='9' className='pt-0'>
                          <span className='me-2' style={{ fontWeight: 600 }}>
                            <i
                              className='bi bi-check-circle-fill'
                              style={{ color: '#5cb85c' }}
                            ></i>{' '}
                            Attending Lord's Day Morning Blending Meeting
                          </span>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
            ) : (
              <tr>
                <td colSpan='9' className='text-center'>
                  No Records
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <PaginationButtons
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </>
  )
}

export default ChurchRegistrationList
